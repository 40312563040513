.formUploadFullWidth {
  :global(.ant-upload.ant-upload-select.ant-upload-select-text) {
    position: relative;
    width: 100%;
  }
  .removeButton {
    position: absolute;
    right: 0;
    top: 0;
  }
}
