.background {
  z-index: -1;
  width: 100%;
  height: 100vh;
  background-color: var(--primary);
  // background-image: url("/images/background-error.png");
  // background-repeat: no-repeat;
  // background-position-x: left;
  // background-position-y: top;
  // -webkit-background-size: 100% 85%;
  // -moz-background-size: 100% 85%;
  // -o-background-size: 100% 85%;
  // background-size: 100% 85%;
}
.logo {
  position: absolute;
  width: 150px;
  top: 25px;
  left: 25px;
}
.moscot {
  position: absolute;
  width: 200px;
  top: 100px;
  left: 250px;
}
.button {
  background-color: transparent;
  color: var(--white);
  padding-left: 30px;
  padding-right: 30px;
}
.wrapper {
  margin-top: 185px;
  margin-left: 0px;
  color: var(--white);
}
.title {
  font-size: 80px;
  margin: 0;
  line-height: 1em;
}
.subtitle {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  line-height: 1.9em;
}
.text {
  font-size: 24px;
  margin-top: 30px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 15px;
}
