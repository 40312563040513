@import "./ant-override-style.scss";
@import "./color.scss";
@import "./scroll.scss";
@import "./shadow-tailwind.scss";
@import "./space.scss";
@import "./typography.scss";
@import "./variable.scss";
@import "./helper.scss";
@import "./layout/common.scss";

body {
  // overflow-y: scroll;
  margin: 0;

  p {
    margin: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wysiwyg-class {
  ol,
  ul {
    padding-inline-start: 25px;
    margin-block-end: 0;
  }
}
