:root {
  //Color Mix
  --primary-100: #fbe0cf;
  --primary-200: #f7bba1;
  --primary-300: #e98a6f;
  --primary-400: #d35c49;
  --primary-500: #b72018;
  --primary-600: #9d1116;
  --primary-700: #830c1a;
  --primary-800: #6a071b;
  --primary-900: #57041c;
  --purple-100: #e7d1fa;
  --purple-200: #cda4f6;
  --purple-300: #a773e5;
  --purple-400: #824dcc;
  --purple-500: #531dab;
  --purple-600: #401593;
  --purple-700: #2f0e7b;
  --purple-800: #200963;
  --purple-900: #160552;
  --violet-100: #c6eef7;
  --violet-200: #90d8f0;
  --violet-300: #54acd3;
  --violet-400: #2979a7;
  --violet-500: #003f6d;
  --violet-600: #00305d;
  --violet-700: #00244e;
  --violet-800: #00193f;
  --violet-900: #001234;
  --success-100: #eafcd0;
  --success-200: #cff9a2;
  --success-300: #aaed71;
  --success-400: #85db4c;
  --success-500: #52c41a;
  --success-600: #3aa813;
  --success-700: #268d0d;
  --success-800: #167108;
  --success-900: #0b5e04;
  --black-025: #fafafb;
  --black-050: #eff0f1;
  --black-075: #e4e6e8;
  --black-100: #d6d9dc;
  --black-150: #c8ccd0;
  --black-200: #bbc0c4;
  --black-300: #9fa6ad;
  --black-350: #9199a1;
  --black-400: #848d95;
  --black-500: #6a737c;
  --black-600: #535a60;
  --black-700: #3c4146;
  --black-750: #2f3337;
  --black-800: #242729;
  --black-900: #0c0d0e;
  --orange-050: #fff7f2;
  --orange-100: #fee3cf;
  --orange-200: #fcd0ad;
  --orange-300: #f7aa6d;
  --orange-400: #f48024;
  --orange-500: #f2720c;
  --orange-600: #da670b;
  --orange-700: #bd5c00;
  --orange-800: #a35200;
  --orange-900: #874600;
  --blue-050: #f2f9ff;
  --blue-100: #cfeafe;
  --blue-200: #addafc;
  --blue-300: #6cbbf7;
  --blue-400: #379fef;
  --blue-500: #0095ff;
  --blue-600: #0077cc;
  --blue-700: #0064bd;
  --blue-800: #0054a3;
  --blue-900: #004487;
  --powder-050: #f4f8fb;
  --powder-100: #e1ecf4;
  --powder-200: #d1e5f1;
  --powder-300: #b3d3ea;
  --powder-400: #a0c7e4;
  --powder-500: #7aa7c7;
  --powder-600: #5b8db1;
  --powder-700: #39739d;
  --powder-800: #2c5777;
  --powder-900: #1e3c52;
  --green-100: #def9dc;
  --green-200: #bbf3bd;
  --green-300: #91dd9b;
  --green-400: #6bbb7d;
  --green-500: #3d8f58;
  --green-600: #2c7a4e;
  --green-700: #1e6644;
  --green-800: #13523b;
  --green-900: #0b4434;
  --yellow-050: #fdf7e3;
  --yellow-100: #fbf2d4;
  --yellow-200: #f1e5bc;
  --yellow-300: #e6d178;
  --yellow-400: #e9c63f;
  --yellow-500: #ddb624;
  --yellow-600: #cea51b;
  --yellow-700: #b89516;
  --yellow-800: #9f8010;
  --yellow-900: #826a0b;
  --red-050: #fdf3f4;
  --red-100: #f9d3d7;
  --red-200: #f4b4bb;
  --red-300: #e87c87;
  --red-400: #de535e;
  --red-500: #d1383d;
  --red-600: #c02d2e;
  --red-700: #ac2726;
  --red-800: #942121;
  --red-900: #7a1819;
  //Color
  --primary: #b72018;
  --white: #fff;
  --black: #0c0d0e;
  --orange: #f48024;
  --yellow: #fbf2d4;
  --green: #3d8f58;
  --blue: #0077cc;
  --powder: #e1ecf4;
  --red: #c02d2e;
  --success: #0b5e04;
  --purple: #531dab;
  --violet: #003f6d;
  --gray: var(--black-050);
  //Color Other
  --gold: #ffcc01;
  --gold-lighter: #fff4d1;
  --gold-darker: #f1b600;
  --silver: #b4b8bc;
  --silver-lighter: #e8e8e8;
  --silver-darker: #9a9c9f;
  --bronze: #caa789;
  --bronze-lighter: #f2e9e1;
  --bronze-darker: #ab825f;
  --fc-dark: #0c0d0e;
  --fc-medium: #3c4146;
  --fc-light: #6a737c;
  --focus-ring: rgba(0, 149, 255, 0.15);
  --focus-ring-success: rgba(166, 217, 183, 0.4);
  --focus-ring-warning: rgba(233, 198, 63, 0.4);
  --focus-ring-error: rgba(192, 45, 46, 0.15);
  --focus-ring-muted: rgba(36, 39, 41, 0.1);
  --bs: 0 1px 4px rgba(0, 21, 41, 0.08);
  --bs-sm: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.05),
    0 2px 8px rgba(0, 0, 0, 0.05);
  --bs-md: 0 1px 3px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.06),
    0 3px 8px rgba(0, 0, 0, 0.09);
  --bs-lg: 0 1px 4px rgba(0, 0, 0, 0.09), 0 3px 8px rgba(0, 0, 0, 0.09),
    0 4px 13px rgba(0, 0, 0, 0.13);
  --scrollbar: rgba(0, 0, 0, 0.2);

  //Typography
  --text-xs: 12px;
  --text-sm: 14px;
  --text-md: 16px;
  --text-lg: 18px;
  --text-xl: 22px;
  --text-bold: 600;
  --text-close: 1em;
  --text-mute: var(--black-300);

  //Other
  --mp-alt-row-color: var(--gray);
  --mp-critical-color: var(--red-600);
  --mp-duration-color: var(--black-800);
  --mp-gap-bg-color: var(--black-025);
  --mp-gap-font-color: var(--black-700);
  --mp-highlight-default-color: var(--fc-dark);
  --mp-highlight-fade-color: var(--yellow-300);
  --mp-highlight-keyword-color: var(--blue-700);
  --mp-highlight-literal-color: var(--green-500);
  --mp-label-color: var(--black-700);
  --mp-link-color: var(--blue-700);
  --mp-main-bg-color: var(--white);
  --mp-muted-color: var(--black-300);
  --mp-popup-shadow: var(--bs-sm);
  --mp-query-border-color: var(--black-100);
  --mp-result-border: solid 0.5px var(--black-300);
  --mp-warning-color: var(--red-600);

  --full-height: 100vh;

  --bs-tailwind-xs: 0 0 0 1px rgba(0, 0, 0, 0.05);
  --bs-tailwind-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --bs-tailwind: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --bs-tailwind-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --bs-tailwind-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --bs-tailwind-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --bs-tailwind-xxl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
