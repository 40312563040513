//Size
.text-xs {
  font-size: var(--text-xs) !important;
}

.text-sm {
  font-size: var(--text-sm) !important;
}

.text-md {
  font-size: var(--text-md) !important;
}

.text-lg {
  font-size: var(--text-lg) !important;
}

.text-xl {
  font-size: var(--text-xl) !important;
}

//Align
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.text-muted {
  color: var(--black-500);
}

//Decor
.text-bold {
  font-weight: var(--text-bold) !important;
}

//Other
.text-cling {
  // line-height: 1.3em;
  line-height: var(--text-close);
}

.text-asterisk {
  color: red;
  font-size: 1.4em;
  margin-left: 5px;
}