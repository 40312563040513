::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}
/* Track */
::-webkit-scrollbar-track {
  background: var(--gray);
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--primary); /* creates padding around scroll thumb */
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px var(--primary);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-100);
}
