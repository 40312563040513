// Background
.bg-success {
  background-color: var(--success) !important;
  border-color: var(--success) !important;
  &:hover {
    background-color: var(--success-600) !important;
    border-color: var(--success-600) !important;
  }
}
.bg-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  &:hover {
    background-color: var(--primary-600) !important;
    border-color: var(--primary-600) !important;
  }
}
.bg-violet {
  background-color: var(--violet) !important;
  border-color: var(--violet) !important;
  &:hover {
    background-color: var(--violet-600) !important;
    border-color: var(--violet-600) !important;
  }
}
.bg-orange {
  background-color: var(--orange) !important;
  border-color: var(--orange) !important;
  &:hover {
    background-color: var(--orange-600) !important;
    border-color: var(--orange-600) !important;
  }
}
.bg-purple {
  background-color: var(--purple) !important;
  border-color: var(--purple) !important;
  &:hover {
    background-color: var(--purple-600) !important;
    border-color: var(--purple-600) !important;
  }
}
.bg-green {
  background-color: var(--green) !important;
  border-color: var(--green) !important;
  span {
    color: var(--white);
  }
  &:hover {
    background-color: var(--green-600) !important;
    border-color: var(--green-600) !important;
    span {
      color: var(--white);
    }
  }
}
.border-green {
  border-color: var(--green) !important;
  span {
    color: var(--green);
  }
  &:hover {
    border-color: var(--green-600) !important;
  }
}
.border-red {
  &:hover {
    border-color: var(--red-600) !important;
    color: var(--red);
    span {
      color: var(--red);
    }
    svg {
      color: var(--red);
    }
  }
}
.bg-gray {
  background-color: var(--black-075) !important;
  border-color: var(--black-075) !important;
  span {
    color: var(--black-600);
  }
  &:hover {
    background-color: var(--black-100) !important;
    border-color: var(--black-100) !important;
    span {
      color: var(--black-600);
    }
  }
}
// Color
.text-violet {
  color: var(--violet) !important;
  &:hover {
    color: var(--violet-600) !important;
  }
}
.text-white {
  color: var(--white) !important;
}
.text-green {
  color: var(--green-500) !important;
}
.text-red {
  color: var(--red-500) !important;
}
.text-primary {
  color: var(--primary) !important;
}
